import React, {useState, useEffect }from 'react';
import { useQuery } from '@tanstack/react-query';
import { useUser, useFirestore } from 'reactfire';
import { collection, getDocs } from 'firebase/firestore';
import "./YourActivitiesDashboard.css";
import YourActivitiesDashboardHeader from "./components/YourActivitiesDashboardHeader";
import YourActivityCard from "./components/YourActivityCard";
import noActivitiesYet from '../../../../assets/Dashboard/YourActivities/noActivitiesImg.png';

// Format the date to "Month Day, Year"
const formattedDate = (date) => date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
});

// Function to fetch worksheet sets from Firestore
const fetchWorksheetSets = async ({ queryKey }) => {
  const [, firestore, user] = queryKey; // Destructure queryKey to get firestore and user
  if (!user) return [];  // Ensure user is available
  const worksheetSetsRef = collection(firestore, 'users', user.uid, 'worksheet_sets');
  const querySnapshot = await getDocs(worksheetSetsRef);

  return querySnapshot.docs.map(doc => (
    {
      id: doc.id,
      activity_name: doc.data().title,
      num_sheets: doc.data().size,
      thumbnail: doc.data().worksheetData[0],
      last_edited: formattedDate(doc.data().createdAt.toDate()),
      last_edited_real: doc.data().createdAt
    }
  )).sort((a, b) => b.last_edited_real.seconds - a.last_edited_real.seconds);
};

function YourActivitiesDashboard() {
  const { data: user } = useUser();
  const firestore = useFirestore();

  const { data: activities = [], isLoading, error } = useQuery({
    queryKey: ['worksheetSets', firestore, user], // Use object form
    queryFn: fetchWorksheetSets, // Function to fetch data
    enabled: !!user, // Only run query if the user exists
    refetchOnWindowFocus: false, // Prevent refetching when window is refocused
  });

  const [filteredActivities, setFilteredActivities] = useState([]);

    // Set filteredActivities to activities when activities data changes
  useEffect(() => {
    if (activities.length > 0) {
      setFilteredActivities(activities);
    }
  }, [activities]);

  return (
    <div className={`yourActivitiesDashboard-container ${activities.length === 0 ? 'empty' : ''}`}>
      <YourActivitiesDashboardHeader allActivities={activities} setFilteredActivities={setFilteredActivities} />

      {!isLoading && (activities.length !== 0) && (
        <div className="yourActivitiesDashboard-grid">
          {filteredActivities.map((activity) => (
            <YourActivityCard key={activity.id} activityData={activity} />
          ))}
        </div>
      )}

      {isLoading && <div>Loading...</div>}
      {error && <div>Error fetching activities...</div>}

      {!isLoading && (activities.length === 0) && (
        <div className="yourActivitiesDashboard-noActivities-container">
          <img
            className="yourActivitiesDashboard-noActivities-img"
            src={noActivitiesYet}
            alt="No Activities Yet"
          />
          <p className="yourActivitiesDashboard-noActivities-text">
            You don&apos;t have any worksheets yet! <br />
            Import or choose a template to get started.
          </p>
        </div>
      )}
    </div>
  );
}

export default YourActivitiesDashboard;
