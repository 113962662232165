import React from 'react';
import PropTypes from 'prop-types';
import { ViewWrap, TextWrap, EmbeddedTextWrap } from '../WorksheetWrappers'; 

import { questionPropType } from '../../pages/CreateWorksheet/CreateWorksheetConstants';

import { splitText } from '../ChineseAccessability';

// Create Document Component
function TextBox({ question, questionIndex, config, isPdf }) {

    return (

        <ViewWrap
            isPdf={isPdf}
            styleProps={ config }
            styleName="textBox"
            elementKey={`textbox-${questionIndex + 1}`}
        >
            <EmbeddedTextWrap
                isPdf={isPdf}
                elementKey={`textbox-content-${questionIndex + 1}`}
                styleProps={ config }
                styleName = ''
            >
                {splitText(question.question_text.concat('\n')).map((part, index) => (
                    <TextWrap
                        isPdf={isPdf}
                        styleProps={ config }
                        styleNames={['questionText', 'inlineText', part.isChinese ? 'chineseText' : '']}
                        elementKey={`textSegment-${index + 1}`}
                        key={`textSegment-${index + 1}`}
                    >
                        {part.text}
                    </TextWrap>
                ))}
            </EmbeddedTextWrap>
            <TextWrap
                        isPdf={isPdf}
                        styleProps={ config }
                        styleNames={['questionText', 'inlineText']}
                        elementKey="textSegment-end"
                    > </TextWrap>
        </ViewWrap>
    );
}

TextBox.propTypes = {
    questionIndex: PropTypes.number,
    question: questionPropType,
    isPdf: PropTypes.bool.isRequired,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
    })
}

export default TextBox;

