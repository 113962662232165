import React from 'react';
import PropTypes from 'prop-types';
import { ViewWrap, TextWrap } from '../WorksheetWrappers';

// Create Document Component
function WrittenResponse({ numLines, questionIndex, config, isPdf }) {
    return (
        <ViewWrap
            isPdf={isPdf}
            styleProps={config}
            elementKey={`writtenResponse-${questionIndex + 1}`}
        >
            {Array.from({ length: numLines }).map((_, lineIndex) => (
                <ViewWrap
                    key={`writingLine-${lineIndex + 1}`}
                    isPdf={isPdf}
                    styleProps={config}
                    styleName='writingLine'
                    elementKey={`writingLine-${lineIndex + 1}`}
                >
                    <TextWrap 
                        isPdf={isPdf}
                        styleProps={config}
                        styleNames={['questionText']}> </TextWrap>
                </ViewWrap>
            ))}
        </ViewWrap>
    );
}

WrittenResponse.propTypes = {
    numLines: PropTypes.number.isRequired,
    questionIndex: PropTypes.number,
    isPdf: PropTypes.bool.isRequired,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
    })
};

export default WrittenResponse;
