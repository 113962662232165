import React from 'react';
import PropTypes from 'prop-types';
import { ViewWrap } from './WorksheetWrappers';

import { singleWorksheetDataPropType } from '../pages/CreateWorksheet/CreateWorksheetConstants';
import AnswersBox from './WorksheetComponents/AnswersBox';
import QuestionText from './WorksheetComponents/QuestionText';
import WorksheetTemplate from './WorksheetTemplate';

const FONT_SIZE = 12;
const LINE_SPACING = 1.5;
const LINES_BETWEEN_QUESTIONS = 1;

// Create Document Component
function FillInTheBlankWorksheet({ worksheetData, worksheetIndex, isPdf = true, config = { fontSize: FONT_SIZE, lineSpacing: LINE_SPACING, linesBetweenQuestions: LINES_BETWEEN_QUESTIONS, answerKey: false } }) {

    return (
        <WorksheetTemplate isPdf = {isPdf} worksheetData={worksheetData} worksheetIndex={worksheetIndex} config={ config }>

            <AnswersBox isPdf = {isPdf} worksheetData={worksheetData} config={config} />

            {worksheetData.questions.map((question, questionIndex) => (
                <ViewWrap
                    isPdf = {isPdf}
                    styleName="questionContainer" styleProps={config}
                    elementKey={`question-${worksheetIndex}-${questionIndex + 1}`}
                    key={`question-${worksheetIndex}-${questionIndex + 1}`}
                >
                    <QuestionText isPdf = {isPdf} question={question} questionIndex={questionIndex} config={config} />
                </ViewWrap>
            ))}
            
        </WorksheetTemplate>
    );
}

FillInTheBlankWorksheet.propTypes = {
    worksheetIndex: PropTypes.number,
    worksheetData: singleWorksheetDataPropType,
    isPdf: PropTypes.bool,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
        linesBetweenQuestions: PropTypes.number,
        answerKey: PropTypes.bool
    })
}

export default FillInTheBlankWorksheet;

