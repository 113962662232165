import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { PDFDownloadLink } from '@react-pdf/renderer';

import '../PreviewWorksheet.css';

import { singleWorksheetDataPropType } from '../../CreateWorksheetConstants';


function ExportDropdown({ worksheetDocument, worksheetData, worksheetIndex, exportToSlides }) {
    const [exportDropdownOpen, setExportDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Dropdown minimize when user clicks off of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                exportDropdownOpen
            ) {
                setExportDropdownOpen(false);
            }
        };

        // Attach the event listener to detect clicks outside the dropdown
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [exportDropdownOpen]);

    return (
        <div
            className="previewWorksheet-dropdown"
            ref={dropdownRef}
        >
            <button
                type="button"
                onClick={() => { setExportDropdownOpen(prev => !prev); }}
                className="previewWorksheet-dropbtn"
            >
                Export
            </button>

            {exportDropdownOpen && (
                <div
                    id="exportOptionsDropdown"
                    className="previewWorksheet-dropdown-content"
                >
                    <PDFDownloadLink
                        document={worksheetDocument}
                        fileName={`[WizLab] ${worksheetData.worksheet_title} (version ${worksheetIndex + 1}).pdf`}
                        as="button"
                    >
                        {({ loading }) => (loading ? 'Loading...' : 'Download as PDF')}
                    </PDFDownloadLink>

                    <button
                        type="button"
                        className="previewWorksheet-dropdown-option"
                        onClick={() => {
                            setExportDropdownOpen(false);
                            exportToSlides(); // Call the passed export function
                        }}
                    >
                        Export to Google Slides
                    </button>
                </div>
            )}
        </div>
    );
};


ExportDropdown.propTypes = {
    worksheetDocument: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    worksheetIndex: PropTypes.number,
    worksheetData: singleWorksheetDataPropType,
    exportToSlides: PropTypes.func,
}


export default ExportDropdown;