import React, { useState } from "react";
import PropTypes from "prop-types";
import "./DifferentiationGroups.css";

import deleteIcon from "../../../../assets/CreateWorksheet/deleteIcon.svg";
import addIcon from "../../../../assets/CreateWorksheet/addIcon.svg";
import worksheetsIcon from "../../../../assets/CreateWorksheet/stackIcon.svg";
import SliderSelector from "./SharedComponents/SliderSelector";

import { diffGroupsPropType, gradeLevelsPropType, readingLevels as readingLevelOptions } from "../../CreateWorksheetConstants";
import { emptyDifferentiaionGroup } from "../../CreateWorksheetObjects";
import ConfigureIEP from "./DifferentiationGroupComponents/ConfigureIEP";

function DifferentiationGroups({ diffGroups, setDiffGroups, gradeLevels, isDiffGroupValid, setIsDiffGroupValid }) {

    const [ expandIEP, setExpandIEP ] = useState([false]);
    const [ expandReading, setExpandReading ] = useState([false]);
    const [ expandMLL, setExpandMLL ] = useState([false]);

    // const handleExpandIEP = (index) => {
    //     setExpandIEP(prevState => {
    //         const newState = prevState.map((isExpanded, idx) =>
    //             idx === index ? !isExpanded : isExpanded
    //         );
    //         return newState;
    //     });
    // };

    const handleExpandReading = (index) => {
        setExpandReading(prevState => {
            const newState = prevState.map((isExpanded, idx) =>
                idx === index ? !isExpanded : isExpanded
            );
            return newState;
        });
    };

    const handleExpandMLL = (index) => {
        setExpandMLL(prevState => {
            const newState = prevState.map((isExpanded, idx) =>
                idx === index ? !isExpanded : isExpanded
            );
            return newState;
        });
    };

    const handleGradeLevelChange = (event, index) => {
        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index];
        targetGroup.grade_level = event.target.value;
		setDiffGroups(newDiffGroups);
	};

    const handleMLLAccomodationChange = (event, index) => {
        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index]

        if (targetGroup === undefined) {
            console.log("Error: cannot find target group");
            return;
        }
        
        const accommodation = event.target.value;
        targetGroup.accommodations.MLL.type = accommodation;
        targetGroup.accommodations.MLL.active = true;
		setDiffGroups(newDiffGroups);
	};

    const handleNumQuestionsChange = (event, index) => {
        // if not a number
        if (!Number(event.target.value) && event.target.value !== "" ) {
            return;
        }
        // if out of range
        if (event.target.value !== "" && 
            (event.target.value < 1 || event.target.value > 20)) {
            return;
        }


        const newDiffGroups = [...diffGroups];
        newDiffGroups[index].num_questions = event.target.value;
        diffGroups[index].num_questions = event.target.value;
		setDiffGroups(newDiffGroups);
	};

    const handleLanguageChange = (event, index) => {
        const newDiffGroups = [...diffGroups];
        newDiffGroups[index].accommodations.MLL.language = event.target.value;
        diffGroups[index].accommodations.MLL.language = event.target.value;
		setDiffGroups(newDiffGroups);
	};

    const handleRemoveGroup = (index) => {
        const newDiffGroups = [...diffGroups];
        newDiffGroups.splice(index, 1);
        setDiffGroups(newDiffGroups);

        const newDiffGroupValid = [...isDiffGroupValid];
        newDiffGroupValid.splice(index, 1)
        setIsDiffGroupValid(newDiffGroupValid);

        const newExpandIEP = [...expandIEP];
        newExpandIEP.splice(index, 1)
        setExpandIEP(newExpandIEP);

        const newExpandReading = [...expandReading];
        newExpandReading.splice(index, 1)
        setExpandReading(newExpandReading);

        const newExpandMLL = [...expandMLL];
        newExpandMLL.splice(index, 1)
        setExpandMLL(newExpandMLL);
    }

    const handleAddGroup = () => {
        const newDiffGroups = [...diffGroups];
        newDiffGroups.push(structuredClone(emptyDifferentiaionGroup));
        setDiffGroups(newDiffGroups);

        const newDiffGroupValid = [...isDiffGroupValid];
        newDiffGroupValid.push({
            grade_level: true,
            num_questions: true,
            language: true
        })
        setIsDiffGroupValid(newDiffGroupValid);

        const newExpandIEP = [...expandIEP];
        newExpandIEP.push(false)
        setExpandIEP(newExpandIEP);

        const newExpandReading = [...expandReading];
        newExpandReading.push(false)
        setExpandReading(newExpandReading);

        const newExpandMLL = [...expandMLL];
        newExpandMLL.push(false)
        setExpandMLL(newExpandMLL);

        console.log(diffGroups);
    }

    const handleReadingLevelChange = (index, option) => {
        console.log("reading level changed to:", option.title, option.descriptor);

        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index]

        if (targetGroup === undefined) {
            console.log("Error: cannot find target group");
            return;
        }
        
        targetGroup.accommodations.reading.reading_level = `${option.title} ${option.descriptor}`;
        targetGroup.accommodations.reading.active = true;
		setDiffGroups(newDiffGroups);
    }


    return (
        <div className="differentiationGroups-container">
            <div>
                <div
                    style={{display: "flex", gap: "5px"}}>
                    <img
                        className="differentiationGroups-title-icon"
                        src={worksheetsIcon} alt="Create you worksheet"/>
                    <h3 className="differentiationGroups-header">Worksheet Versions:</h3>
                    <p style={{color: "red", margin: "0"}}>*</p>
                </div>
                <p className="differentiationGroups-description">Create your differentiated worksheet versions here!</p>
            </div>
            
            <div className="differentiationGroups-groups">
            {diffGroups.map((group, index) => (
                <div className="differentiationGroups-group" key={`Group ${index + 1}`} >

                    {/* Header */}
                    <div style={{width: "100%"}}>
                    <div className="differentiationGroups-group-header">
                        <h4 className="differentiationGroups-group-title"> Version {index + 1}</h4>
                        <button 
                            className="differentiationGroups-group-removeIcon"
                            type="button"
                            onClick={() => handleRemoveGroup(index)}>
                            <img 
                                className="differentiationGroups-group-removeIcon"
                                src={deleteIcon} alt="Remove group"/>
                        </button>
                    </div>
                    <div className="differentiationGroups-group-lineSeperator"/>
                    </div>
                    
                    <div
                        style={{display: "flex", width: "100%", gap: "15px"}}
                    >
                    {/* Grade Level Selection */}
                    <div className="differentiationGroups-group-section">
                        <label 
                            className="differentiationGroups-group-label"
                            htmlFor={`gradeLevelSelector-${group.id}`}
                            >Difficulty Level:
                            <p style={{color: "red", margin: "0"}}>*</p>
                        </label>
                        <select
                            className={`differentiationGroups-group-selector ${!isDiffGroupValid[index].grade_level ? 'input-invalid' : ''}`}
                            id={`gradeLevelSelector-${index + 1}`}
                            value={group.grade_level}
                            onChange={(e) => handleGradeLevelChange(e, index)}
                        >
                            <option value="">Select a grade level</option>
                            {gradeLevels.map((gradeLevel) => (
                                <option key={gradeLevel.id} value={gradeLevel.name}>
                                    {gradeLevel.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Num Questions Input */}
                    <div className="differentiationGroups-group-section">
                        <label 
                            className="differentiationGroups-group-label"
                            htmlFor="createWorksheet-numQuestions"
                            >Number of Questions (1-20):
                            <p style={{color: "red", margin: "0"}}>*</p>
                        </label>
                        <input 
                            type="text" 
                            className={`differentiationGroups-group-textInput ${!isDiffGroupValid[index].num_questions ? 'input-invalid' : ''}`}
                            id="createWorksheet-numQuestions" 
                            name="createWorksheet-numQuestions"
                            placeholder=" "
                            autoComplete="off"
                            value={diffGroups[index].num_questions}
                            onChange={(e) => handleNumQuestionsChange(e, index)}/>
                    </div>
                    </div>

                    
                    {/* Accommodations Selection */}
                    <div className="differentiationGroups-group-section">
                        <div className="differentiationGroups-group-label"
                        >
                            Accommodations:
                            <p style={{color: "var(--mid-light-grey)", alignSelf: "flex-end", fontSize: "14px", margin: "0", paddingBottom: "2px"}}>
                                (optional)
                            </p>
                        </div>
                        <ConfigureIEP
                            index={index}
                            diffGroups={diffGroups}
                            setDiffGroups={setDiffGroups}
                        />

                        <div className="differentiationGroups-accommodations-option">
                            <input 
                                className="differentiationGroups-accommodations-option-checkbox"
                                type="checkbox" 
                                id="accommodations-MLL" 
                                name="MLL" 
                                onClick={() => handleExpandMLL(index)}/>
                            <label 
                                className="differentiationGroups-accommodations-option-label"
                                htmlFor="accommodations-MLL"
                                >Multilingual Language Learner</label><br/>
                        </div>
                        {/* MLL Input */}
                        { expandMLL[index] && 
                            <div
                                style={{display: "flex", flexDirection: "column", gap: "5px", marginLeft: "15px"}}
                            >
                            <div className="differentiationGroups-accommodations-option">
                                <input 
                                    className="differentiationGroups-accommodations-option-checkbox"
                                    type="radio" 
                                    id="accommodations-full" 
                                    name={`accommodations-MLL-group-${index + 1}`} 
                                    value="Full Translation"
                                    onClick={(e) => handleMLLAccomodationChange(e, index)}/>
                                <label 
                                    className="differentiationGroups-accommodations-option-label"
                                    htmlFor="accommodations-full"> 
                                    Full Translations</label><br/>
                            </div>
                            <div className="differentiationGroups-accommodations-option">
                                <input 
                                    className="differentiationGroups-accommodations-option-checkbox"
                                    type="radio" 
                                    id="accommodations-wordBased" 
                                    name={`accommodations-MLL-group-${index + 1}`} 
                                    value="Word based Translation"
                                    onClick={(e) => handleMLLAccomodationChange(e, index)}/>
                                <label 
                                    className="differentiationGroups-accommodations-option-label"
                                    htmlFor="accommodations-wordBased"> 
                                    Translate Difficult Words</label><br/>
                            </div>
                            <div className="differentiationGroups-accommodations-option">
                                <input 
                                    className="differentiationGroups-accommodations-option-checkbox"
                                    type="radio" 
                                    id="accommodations-definition" 
                                    name={`accommodations-MLL-group-${index + 1}`} 
                                    value="English Definitions"
                                    onClick={(e) => handleMLLAccomodationChange(e, index)}/>
                                <label 
                                    className="differentiationGroups-accommodations-option-label"
                                    htmlFor="accommodations-definitions"> 
                                    Definitions in Easier English Words</label><br/>
                            </div>
                            {/* Language Input */}
                            { group.accommodations.MLL.active && group.accommodations.MLL.type !== "English Definitions" && 
                                <div className="differentiationGroups-group-section">
                                    <label 
                                        className="differentiationGroups-group-label"
                                        htmlFor="createWorksheet-language"
                                    >
                                        Translation Language:
                                        <p style={{color: "red", margin: "0"}}>*</p>
                                    </label>
                                    <input 
                                        type="text" 
                                        className={`differentiationGroups-group-textInput ${!isDiffGroupValid[index].language ? 'input-invalid' : ''}`}
                                        id="createWorksheet-language" 
                                        name="createWorksheet-language"
                                        placeholder=" "
                                        autoComplete="off"
                                        value={diffGroups[index].accommodations.MLL.language}
                                        onChange={(e) => handleLanguageChange(e, index)}/>
                                </div>
                            }
                            </div>
                        }

                        <div className="differentiationGroups-accommodations-option">
                            <input 
                                className="differentiationGroups-accommodations-option-checkbox"
                                type="checkbox" 
                                id="accommodations-readingLevel" 
                                name="accommodations-readingLevel" 
                                value="Reading Level"
                                onClick={() => handleExpandReading(index)}/>
                            <label 
                                className="differentiationGroups-accommodations-option-label"
                                htmlFor="accommodations-readingLevel"> 
                                Reading Level</label><br/>
                        </div>
                        {/* Reading Level Input */}
                        { expandReading[index] && 
                            <div className="differentiationGroups-group-section">
                                <SliderSelector
                                    options={readingLevelOptions}
                                    hasDescriptor
                                    handleChange={(option) => handleReadingLevelChange(index, option)}    
                                />
                            </div>
                        }
                    </div>
                </div>
            ))}
            </div>
            { diffGroups.length <= 6 && 
                <button
                    className="differentiationGroups-addGroup-button"
                    type="button"
                    onClick={handleAddGroup}>
                    <img 
                        className="differentiationGroups-addGroup-icon"
                        src={addIcon} alt="Add Group"/>
                    Add Group
                </button>
            }
        </div>
  );
}

DifferentiationGroups.propTypes = {
    diffGroups: diffGroupsPropType,
    setDiffGroups: PropTypes.func,
    gradeLevels: gradeLevelsPropType,
    isDiffGroupValid: PropTypes.arrayOf(
        PropTypes.shape({
            grade_level: PropTypes.bool,
            num_questions: PropTypes.bool,
            language: PropTypes.bool
        })
    ),
    setIsDiffGroupValid: PropTypes.func,
};

export default DifferentiationGroups;
