import React from 'react';
import PropTypes from 'prop-types';
import { ViewWrap, TextWrap } from './WorksheetWrappers';

import { singleWorksheetDataPropType } from '../pages/CreateWorksheet/CreateWorksheetConstants';
import QuestionText from './WorksheetComponents/QuestionText';
import WorksheetTemplate from './WorksheetTemplate';
import WrittenResponse from './WorksheetComponents/WrittenResponse';

const FONT_SIZE = 12;
const LINE_SPACING = 1.5;

// Create Document Component
function WrittenResponseWorksheet({ worksheetData, worksheetIndex, isPdf, config = { fontSize: FONT_SIZE, lineSpacing: LINE_SPACING, answerKey: false } }) {

    return (
        <WorksheetTemplate isPdf={isPdf} worksheetData={worksheetData} worksheetIndex={worksheetIndex} config={config}>

            {worksheetData.questions.map((question, questionIndex) => (
                <ViewWrap
                    isPdf={isPdf}
                    styleName="questionContainer" styleProps={config}
                    elementKey={`question-${worksheetIndex}-${questionIndex + 1}`}
                >
                    {(() => {
                        switch (question.question_type) {
                            case 'short answer':
                                return <ViewWrap
                                    isPdf={isPdf}
                                    styleName="" styleProps={config}
                                ><QuestionText isPdf={isPdf} question={question} questionIndex={questionIndex} config={config} />
                                    <WrittenResponse isPdf={isPdf} numLines={1} config={config} />
                                </ViewWrap>
                            case 'medium answer':
                                return <ViewWrap
                                    isPdf={isPdf}
                                    styleName="" styleProps={config}
                                ><QuestionText isPdf={isPdf} question={question} questionIndex={questionIndex} config={config} />
                                    <WrittenResponse isPdf={isPdf} numLines={3} config={config} />
                                </ViewWrap>
                            case 'long answer':
                                return <ViewWrap
                                    isPdf={isPdf}
                                    styleName="" styleProps={config}
                                ><QuestionText isPdf={isPdf} question={question} questionIndex={questionIndex} config={config} />
                                    <WrittenResponse isPdf={isPdf} numLines={5} config={config} />
                                </ViewWrap>
                            default:
                                return <TextWrap isPdf={isPdf} styleProps={config} styleNames={['']}>Unknown Question Type</TextWrap>
                        }
                    })()}
                </ViewWrap>
            ))}

        </WorksheetTemplate>
    );
}

WrittenResponseWorksheet.propTypes = {
    worksheetIndex: PropTypes.number,
    worksheetData: singleWorksheetDataPropType,
    isPdf: PropTypes.bool,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
        answerKey: PropTypes.bool
    })
}

export default WrittenResponseWorksheet;

