import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { initializeFirestore } from 'firebase/firestore'
import { useSigninCheck, FirestoreProvider, useInitFirestore } from 'reactfire';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import "./App.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import MyLab from "./pages/MyLab/MyLab";
import TeacherProfile from "./pages/TeacherProfile/TeacherProfile";
import CreateWorksheet from "./pages/CreateWorksheet/CreateWorksheet";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction";
import OAuthCallback from "./pages/AuthPages/OAuthCallback";
import SignIn from "./pages/AuthPages/SignIn";
import LoadingBar from "./components/LoadingBar/LoadingBar";
import EditWorksheetTest from "./pages/EditWorksheetTEST/EditWorksheet";
import EditWorksheet from "./pages/EditWorksheet/EditWorksheet";
import { NavbarProvider } from "./components/NavBar/NavBar";

import './fonts/AvenirNextLTPro-Regular.otf';

// Create a client
const queryClient = new QueryClient();

function App() {

	const { status: authStatus, data: signInCheckResult } = useSigninCheck();

	const { status: dbStatus, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
		const db = initializeFirestore(firebaseApp, {});
		return db;
	});

	if (authStatus === 'loading' || dbStatus === 'loading') {
		return (
			<LoadingBar duration="2" />
		);
	}

	if (signInCheckResult.signedIn === false) {
		return (
			<SignIn />
		);
	}

	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<FirestoreProvider sdk={firestoreInstance}>
					<NavbarProvider>
						<Routes>
							<Route path="" element={<Dashboard />} />
							<Route path="home" element={<Dashboard />} />
							<Route path="my-lab" element={<MyLab />} />
							<Route path="profile" element={<TeacherProfile />} />
							<Route path="create-worksheet" element={<CreateWorksheet />} />
							<Route path="edit-worksheet" element={<EditWorksheet />} />
							<Route path="explore" element={<UnderConstruction />} />
							<Route path="statistics" element={<UnderConstruction />} />
							<Route path="community" element={<UnderConstruction />} />
							<Route path="help" element={<UnderConstruction />} />
							<Route path="/oauth-callback" element={<OAuthCallback />} />

							<Route path="test" element={<EditWorksheetTest />} />
						</Routes>
					</NavbarProvider>
				</FirestoreProvider>
			</Router>
		</QueryClientProvider>
	);
}

export default App;
