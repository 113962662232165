import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { logEvent } from "firebase/analytics";
import { useFunctions, useUser, useAnalytics, useFirestore } from 'reactfire';
import { doc, setDoc, deleteDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import "./CreateWorksheet.css";
import Navbar from "../../components/NavBar/NavBar";
import PreviewEditWorksheet from "./PreviewEditView/PreviewEditWorksheet";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import ConfigureWorksheet from "./ConfigureView/ConfigureWorksheet";
import FinishWorksheet from "./FinishView/FinishWorksheet";
import ErrorScreen from "../ErrorScreen/ErrorScreen";

import { gradeLevels } from "./CreateWorksheetConstants";
import { emptyDifferentiaionGroups, emptyGeneralInfo } from './CreateWorksheetObjects';

// import { sampleWorksheetMultipleChoice as sampleWorksheetResultData } from "./CreateWorksheetSampleData";


function CreateWorksheet() {
    const location = useLocation();
    const navigate = useNavigate();
    const { inputType, worksheetTemplate } = location.state || {};

    const analytics = useAnalytics();

    const [file, setFile] = useState(null);

    const [imageEncodings, setImageEncodings] = useState([]);

    // STATUSES: initial, loading, returned, finish, error
    const [status, setStatus] = useState("initial");

    const [apiMessage, setAPIMessage] = useState([]); // sampleWorksheetResultData

    const [diffGroups, setDiffGroups] = useState(structuredClone(emptyDifferentiaionGroups));

    const [generalInfo, setGeneralInfo] = useState(emptyGeneralInfo);

    const [worksheetData, setWorksheetData] = useState([]); // sampleWorksheetResultData

    const [wid, setWid] = useState('');

    // Cloud Functions
    const functions = useFunctions();
    const generateWorksheetCloudFunction = httpsCallable(functions, 'generateWorksheetWithImageInput');
    const generateWorksheetBatchCloudFunction = httpsCallable(functions, 'generateWorksheetBatch');

    // Auth
    const { data: user } = useUser();

    // Access Firestore and Auth services
    const firestore = useFirestore();

    // Redirect if parameters not defined
    if (inputType === undefined || worksheetTemplate === undefined) {
        navigate('/home');
    }

    /* eslint-disable no-unused-vars */
    const uploadToFirebaseTest = async (groupIndex, fileEncodings) => {
        try {
            setStatus('loading');
            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken(true);
            // console.log(token);

            console.log({
                inputType,
                originalGradeLevel: generalInfo.grade_level,
                gradeLevel: diffGroups[groupIndex].grade_level,
                numQuestions: diffGroups[groupIndex].num_questions,
                worksheetType: worksheetTemplate,
                alignToStandards: generalInfo.standards,
                accommodations: diffGroups[groupIndex].accommodations,
                language: diffGroups[groupIndex].language,
                fileName: file.name,
                fileEncodings,
            });

            const response = await fetch('http://127.0.0.1:5001/wizlab-8c906/us-central1/generateWorksheetWithImageInput', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Access-Control-Max-Age': '3600',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    data: {
                        inputType,
                        originalGradeLevel: generalInfo.grade_level,
                        gradeLevel: diffGroups[groupIndex].grade_level,
                        numQuestions: diffGroups[groupIndex].num_questions,
                        worksheetType: worksheetTemplate,
                        alignToStandards: generalInfo.standards,
                        accommodations: diffGroups[groupIndex].accommodations,
                        fileName: file.name,
                        fileEncodings,
                    }
                }),
            });

            if (!response.ok) {
                setStatus("error");
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log(responseData.result);
            return responseData.result;
        } catch (error) {
            setStatus("error");
            console.error('Error uploading to Firebase:', error);
            throw error; // Re-throw the error if you want to handle it further up the call stack
        }
    };

    /* eslint-disable no-unused-vars */
    const uploadToFirebaseCallVersion = async (groupIndex, fileEncodings) => {
        try {
            setStatus('loading');

            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken();
            // console.log("Token fetched: ", token);
            console.log({
                inputType,
                originalGradeLevel: generalInfo.grade_level,
                gradeLevel: diffGroups[groupIndex].grade_level,
                numQuestions: diffGroups[groupIndex].num_questions,
                worksheetType: worksheetTemplate,
                alignToStandards: generalInfo.standards,
                accommodations: diffGroups[groupIndex].accommodations,
                fileName: file.name,
                fileEncodings,
            });

            const result = await generateWorksheetCloudFunction({
                inputType,
                originalGradeLevel: generalInfo.grade_level,
                gradeLevel: diffGroups[groupIndex].grade_level,
                numQuestions: diffGroups[groupIndex].num_questions,
                worksheetType: worksheetTemplate,
                alignToStandards: generalInfo.standards,
                accommodations: diffGroups[groupIndex].accommodations,
                fileName: file.name,
                fileEncodings,
            },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            console.log(result);

            logEvent(analytics, 'worksheet_generated_successfuly', { user: user.displayName });

            // const responseData = await result.json();
            const responseData = result.data;
            console.log(responseData);
            return responseData;
        } catch (error) {
            setStatus("error");
            console.error('Error uploading to Firebase:', error);
            throw error; // Re-throw the error if you want to handle it further up the call stack
        }
    };

    /* eslint-disable no-unused-vars */
    const uploadToFirebaseBatchTest = async (fileEncodings) => {
        try {
            setStatus('loading');
            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken(true);
            // console.log(token);

            console.log({
                inputType,
                originalGradeLevel: generalInfo.grade_level,
                worksheetType: worksheetTemplate,
                alignToStandards: generalInfo.standards,
                accommodations: diffGroups,
                fileName: file.name, 
                fileEncodings,
            });

            const response = await fetch('http://127.0.0.1:5001/wizlab-8c906/us-central1/generateWorksheetBatch', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',  
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Headers': 'Content-Type', 
                    'Access-Control-Max-Age': '3600',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    data: {
                        inputType,
                        originalGradeLevel: generalInfo.grade_level,
                        worksheetType: worksheetTemplate,
                        alignToStandards: generalInfo.standards,
                        accommodations: diffGroups,
                        fileName: file.name, 
                        fileEncodings,
                    }
                }),
            });
        
            if (!response.ok) {
                setStatus("error");
                throw new Error('Network response was not ok');
            }
        
            const responseData = await response.json();
            console.log(responseData.result);
            return responseData.result;
        } catch (error) {
            setStatus("error");
            console.error('Error uploading to Firebase:', error);
            throw error; // Re-throw the error if you want to handle it further up the call stack
        }
    };

    /* eslint-disable no-unused-vars */
    const uploadToFirebaseBatchCallVersion = async (fileEncodings) => {
        try {
            setStatus('loading');
            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken(true);
            // console.log(token);

            console.log({
                inputType,
                originalGradeLevel: generalInfo.grade_level,
                worksheetType: worksheetTemplate,
                alignToStandards: generalInfo.standards,
                accommodations: diffGroups,
                fileName: file.name, 
                fileEncodings,
            });

            const response = await generateWorksheetBatchCloudFunction({
                    inputType,
                    originalGradeLevel: generalInfo.grade_level,
                    worksheetType: worksheetTemplate,
                    alignToStandards: generalInfo.standards,
                    accommodations: diffGroups,
                    fileName: file.name, 
                    fileEncodings,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                }
            );

            logEvent(analytics, 'worksheet_generated_successfuly', { user: user.displayName });

            const responseData = response.data;
            console.log(responseData);
            return responseData;
        } catch (error) {
            setStatus("error");
            console.error('Error uploading to Firebase:', error);
            throw error; // Re-throw the error if you want to handle it further up the call stack
        }
    };

    const handleUpload = async () => {
        try {
            if (file) {
                setAPIMessage([]);

                const results = await uploadToFirebaseBatchCallVersion(imageEncodings);

                const allWorksheetData = results.map((result, index) => {
                    const questionsArray = Array.isArray(result) ? result : result.questions;
                    const worksheetDataOutput = {
                        worksheet_title: generalInfo.worksheet_name,
                        worksheet_type: worksheetTemplate,
                        grade_level: diffGroups[index].grade_level,
                        accommodations: diffGroups[index].accommodations,
                        num_questions: diffGroups[index].num_questions,
                        questions: questionsArray,
                    }
                    return worksheetDataOutput;
                });
                console.log(allWorksheetData);
                setAPIMessage(allWorksheetData);
                setStatus("returned");
            };
        } catch (error) {
            setStatus("error");
            console.error('Error in handleUpload:', error);
        }
    };

    async function addWorksheetsForUser(worksheetCollectionTitle, worksheetsArray, currentWorksheetID) {
        try {
            if (!user) {
                console.error("No user is signed in");
                return;
            }

            // Reference the 'worksheets' subcollection
            const worksheetsDocRef = doc(firestore, 'users', user.uid, 'worksheet_sets', currentWorksheetID);

            // Set or update fields directly in the 'wid' document
            await setDoc(worksheetsDocRef, {
                // your data fields go here
                title: worksheetCollectionTitle,
                size: worksheetsArray.length,
                worksheetData: worksheetsArray,
                createdAt: new Date()
            });

            console.log('All worksheets added successfully.');
        } catch (error) {
            console.error('Error adding worksheets: ', error.message);
        }
    }

    const finishAndSave = async (worksheetDataFinal) => {

        let currentWorksheetID = wid;

        if (!currentWorksheetID) {
            currentWorksheetID = uuidv4();
            setWid(currentWorksheetID);
        }

        console.log("worksheet data final:", worksheetDataFinal);

        await addWorksheetsForUser(worksheetDataFinal[0].worksheet_title, worksheetDataFinal, currentWorksheetID);
        setWorksheetData(worksheetDataFinal);
        setStatus("finish");
    }

    const deleteWorksheetSet = async () => {
        try {
            if (!user) {
                console.error("No user is signed in");
                return;
            }

            // Reference the specific worksheet set document in the 'worksheet_sets' subcollection
            const worksheetDocRef = doc(firestore, 'users', user.uid, 'worksheet_sets', wid);

            // Delete the document
            await deleteDoc(worksheetDocRef);

            navigate('/home');

            console.log('Worksheet set deleted successfully.');
        } catch (error) {
            console.error('Error deleting worksheet set: ', error.message);
        }
    }

    const deleteWorksheet = async (index) => {
        try {
            // Get the current worksheet data
            setWorksheetData((prevWorksheetData) => {
                // Check if index is within bounds
                if (index >= 0 && index < prevWorksheetData.length && prevWorksheetData.length > 1) {
                    // Create a new array without the worksheet at the given index
                    const updatedWorksheetData = [...prevWorksheetData];
                    updatedWorksheetData.splice(index, 1);
                    finishAndSave(updatedWorksheetData);
                    // Return the updated array to update the state
                    return updatedWorksheetData;
                }
                deleteWorksheetSet();
                return prevWorksheetData;
            });

            console.log(`Worksheet at index ${index} deleted successfully.`);
        } catch (error) {
            console.error('Error deleting worksheet: ', error.message);
        }
    };

    return (
        <div className="base-container">
            <Navbar minimizedByDefault />
            <div className="base-content-container">
                <div className="createWorksheet-container">
                    {status === 'error' &&
                        <ErrorScreen/>
                    }
                    {status === 'loading' &&
                        <LoadingBar duration="26" />
                    }
                    {status === "initial" &&
                        <ConfigureWorksheet
                            file={file}
                            setFile={setFile}
                            imageEncodings={imageEncodings}
                            setImageEncodings={setImageEncodings}
                            inputType={inputType}
                            gradeLevels={gradeLevels}
                            diffGroups={diffGroups}
                            setDiffGroups={setDiffGroups}
                            generalInfo={generalInfo}
                            setGeneralInfo={setGeneralInfo}
                            handleUpload={handleUpload}
                        />
                    }
                    {status === "returned" &&
                        <PreviewEditWorksheet
                            worksheetDataInput={apiMessage}
                            finishAndSave={finishAndSave}
                        />
                    }
                    {status === "finish" && worksheetData &&
                        <FinishWorksheet
                            worksheetData={worksheetData}
                            setDiffGroups={setDiffGroups}
                            setStatus={setStatus}
                            deleteWss={deleteWorksheetSet}
                            deleteWs={deleteWorksheet}
                        />
                    }
                </div>
            </div>
        </div>
    );
}


export default CreateWorksheet;
