import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import "./CreateActivityPopup.css";

import differentiateIcon from '../../../../assets/Dashboard/CreateActivityPopup/differentiateIcon.svg';
import uploadIcon from '../../../../assets/Dashboard/CreateActivityPopup/uploadIcon.svg';
import multipleChoiceIcon from '../../../../assets/Dashboard/ActivityIcons/multipleChoiceIcon.png';
import fillInTheBlankIcon from '../../../../assets/Dashboard/ActivityIcons/fillInTheBlankIcon.png';
import readingComprehensionIcon from '../../../../assets/Dashboard/ActivityIcons/readingComprehensionIcon.png';
import shortAnswerIcon from '../../../../assets/Dashboard/ActivityIcons/shortAnswerIcon.png';

import exitIcon from '../../../../assets/shared/exitIcon-black.png';

function CreateActivityPopup({ closePopup, inputType, template }) {
    const [showTemplates] = useState(template === "");

    const navigate = useNavigate();

    return (
      <div className="popup-overlay">
        <div className="popup">
          
          <div className="popup-header">
            <button type="button" onClick={closePopup} className="popup-close-button">
              <img src={exitIcon} alt="exit"/></button>
            <h2 className="popup-title">{ showTemplates ? "Choose Worksheet Template" : "Choose Generation Method"}</h2>
          </div>
          <div className="popup-body">
            <p className="popup-description">Click to select how your worksheet will be generated.</p>
            <div className="popup-options-container">
                { showTemplates && 
                  <>
                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => navigate('/create-worksheet', { state: { inputType, worksheetTemplate: "multiple choice"} })}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={multipleChoiceIcon} alt="Multiple Choice"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Multiple Choice</h2>
                        <p className="popup-tag">Suitable for all grades!</p>
                      </div>
                  </button>

                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => navigate('/create-worksheet', { state: { inputType, worksheetTemplate: "fill in the blank"} })}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={fillInTheBlankIcon} alt="Fill In The Blank"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Fill in the Blank</h2>
                        <p className="popup-tag">Suitable for all grades!</p>
                      </div>
                  </button>
                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => navigate('/create-worksheet', { state: { inputType, worksheetTemplate: "reading comprehension"} })}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={readingComprehensionIcon} alt="Reading Comprehension"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Reading Comprehension</h2>
                        <p className="popup-tag">Suitable for all grades!</p>
                      </div>
                  </button>
                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => navigate('/create-worksheet', { state: { inputType, worksheetTemplate: "written response"} })}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={shortAnswerIcon} alt="Written Response"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Written Response</h2>
                        <p className="popup-tag">Suitable for all grades!</p>
                      </div>
                  </button>
                  </>
                }
                { !showTemplates &&
                  <>
                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => navigate('/create-worksheet', { state: { inputType: "worksheet", worksheetTemplate: template} })}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={differentiateIcon} alt="Input an Existing Worksheet"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Import Your Worksheet</h2>
                        <p className="popup-tag">Any worksheet works!</p>
                      </div>
                  </button>

                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => navigate('/create-worksheet', { state: { inputType: "teaching resource", worksheetTemplate: template} })}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={uploadIcon} alt="Generate from PDF"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Generate from PDF</h2>
                        <p className="popup-tag">Lesson plan, textbook , etc</p>
                      </div>
                  </button>
                  </>
                }

                
            </div>
          </div>
        </div>
      </div>
    );
}

CreateActivityPopup.propTypes = {
    closePopup: PropTypes.func,
    inputType: PropTypes.string,
    template: PropTypes.string,
};

export default CreateActivityPopup;

// <button type="button" className="popup-option">
                //     <img className="popup-option-icon" src={uploadIcon} alt="Generate Your Worksheet from PDF"/>
                //     <h2 className="popup-option-title">Generate from PDF</h2>
                //     <p className="popup-option-description">Generate differentiated worksheets from a PDF like a texbook excerpt, syllabus, lesson plan, etc</p>
                // </button> 