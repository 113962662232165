import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import "./ConfigureWorksheet.css";
import DragAndDrop from "./components/DragAndDrop";
import DifferentiationGroups from "./components/DifferentiationGroups";
import AlignToStandards from "./components/AlignToStandards";
import nameIcon from '../../../assets/CreateWorksheet/pencilIcon.svg';
import gradeIcon from '../../../assets/CreateWorksheet/targetIcon.svg';
import generateIcon from '../../../assets/CreateWorksheet/generateIcon.svg';

import { emptyDifferentiaionGroups } from "../CreateWorksheetObjects";
import { diffGroupsPropType, generalInfoPropType, gradeLevelsPropType } from "../CreateWorksheetConstants";


function ConfigureWorksheet({ file, setFile, setImageEncodings, inputType, gradeLevels, diffGroups, setDiffGroups, generalInfo, setGeneralInfo, handleUpload }) {
    const location = useLocation();

    useEffect(() => {
        const resetDiffGroups = () => {
            setDiffGroups(structuredClone(emptyDifferentiaionGroups)); // Reset diffGroups to an empty array or initial state
        };

        // Call resetDiffGroups when the component unmounts
        return () => {
            resetDiffGroups();
        };
    }, [setDiffGroups]);

    useEffect(() => {
        const resetDiffGroups = () => {
            setDiffGroups(structuredClone(emptyDifferentiaionGroups)); // Reset diffGroups to an empty array or initial state
        };

        // Call resetDiffGroups when the location changes
        resetDiffGroups();
    }, [location, setDiffGroups]);

    const [isParentFormValid, setIsParentFormValid] = useState({
        worksheet_name: true,
        grade_level: true,
        file: true,
    });

    const [isDiffGroupValid, setIsDiffGroupValid] = useState(diffGroups.map(() => ({
        grade_level: true,
        num_questions: true,
        language: true
    })));

    const handleGeneralInfoGradeLevelChange = (event) => {
        setGeneralInfo({
            ...generalInfo, // Copy the old fields
            grade_level: event.target.value // But override this one
          });
	};

    const handleWorksheetNameChange = (event) => {
        setGeneralInfo({
            ...generalInfo, // Copy the old fields
            worksheet_name: event.target.value // But override this one
          });
	};

    const checkConfigureFormValid = () => {
        const newIsValid = {
            worksheet_name: !!generalInfo.worksheet_name,
            grade_level: !!generalInfo.grade_level,
            file: !!file,
        };
        setIsParentFormValid(newIsValid);
    
        const allValid = Object.values(newIsValid).every(Boolean);
        
        if (allValid) 
            return true;
        console.log("generalInfo invalid")
        return false;
    }

    const validateDiffGroups = () => {
        const newIsDiffGroupValid = diffGroups.map(group => ({
            grade_level: !!group.grade_level,
            num_questions: !!group.num_questions,
            language: group.accommodations.MLL.active && group.accommodations.MLL.type !== "English Definitions" 
                        ? group.accommodations.MLL.language !== "" : true,
        }));
    
        setIsDiffGroupValid(newIsDiffGroupValid);
        
        if (!newIsDiffGroupValid.every(group => Object.values(group).every(Boolean)))
            console.log("diff groups invalid");
    
        return newIsDiffGroupValid.every(group => Object.values(group).every(Boolean));
    };

    const handleSubmit = () => {
        const isGeneralInfoValid = checkConfigureFormValid();
        const isDiffGroupsValid = validateDiffGroups();
    
        if (isGeneralInfoValid && isDiffGroupsValid) {
            handleUpload();
        } else {
            console.log("Please fill in all required fields.");
        }
    };


    return (
        <>
        <h1 className="createWorksheet-header">{inputType === "worksheet" ? "Differentiate Your Worksheet" : "Generate Personalized Worksheets"}</h1>
        <p className="createWorksheet-subHeader"
            style={{marginTop: "10px"}}>
            Import a PDF or Image and choose how you want your worksheets to be personalized.
        </p>

        <div className="createWorksheet-upload-container">
            <DragAndDrop 
                file={file} 
                setFile={setFile}
                setImageEncodings={setImageEncodings}
                inputType={inputType} 
                fileImported={isParentFormValid.file}/>
        </div>
        
        <div className="createWorksheet-configure-container">
            <div className="createWorksheet-configure-info">
                <div className="createWorksheet-configure-info-section">
                    <label
                        className="createWorksheet-configure-info-header"
                        htmlFor="createWorksheet-configure-info-name"
                        >
                        <img className="createWorksheet-configure-field-icon"
                            style={{width: "18px", height: "18px"}}
                            src={nameIcon} alt="Name of Worksheet"/>
                            Name of your worksheet:
                        <p style={{color: "red", margin: "0"}}>*</p>
                    </label>
                    <input 
                        type="text"
                        id="createWorksheet-configure-info-name" 
                        name="createWorksheet-configure-info-name"
                        className={`createWorksheet-configure-info-textInput ${!isParentFormValid.worksheet_name ? 'input-invalid' : ''}`}
                        placeholder="Know your 5 times tables!"
                        autoComplete="off"
                        onChange={handleWorksheetNameChange}/>
                </div>
                <div className="createWorksheet-configure-info-section">
                    <label 
                        className="createWorksheet-configure-info-header"
                        htmlFor="createWorksheet-configure-info-gradeLevelSelect"
                        >
                        <img className="createWorksheet-configure-field-icon"
                            src={gradeIcon} alt="Name of Worksheet"/>
                            Grade level of your {inputType === "worksheet" ? "worksheet" : "textbook"}:
                        <p style={{color: "red", margin: "0"}}>*</p>
                    </label>
                    <select
                        className={`createWorksheet-configure-info-dropdown ${!isParentFormValid.grade_level ? 'input-invalid' : ''}`}
                        id="createWorksheet-configure-info-gradeLevelSelect"
                        value={generalInfo.grade_level}
                        onChange={(e) => handleGeneralInfoGradeLevelChange(e)}
                    >
                    <option value="">Select a grade level</option>
                    {gradeLevels.map((gradeLevel) => (
                        <option key={gradeLevel.id} value={gradeLevel.name}>
                            {gradeLevel.name}
                        </option>
                    ))}
                    </select>
                </div>
            </div>
            <div className="createWorksheet-configure-info">
                <div className="createWorksheet-configure-info-section">
                    <AlignToStandards generalInfo={generalInfo} setGeneralInfo={setGeneralInfo}/>
                </div>
            </div>
            <DifferentiationGroups 
                diffGroups={diffGroups} 
                setDiffGroups={setDiffGroups}
                gradeLevels={gradeLevels}
                isDiffGroupValid={isDiffGroupValid}
                setIsDiffGroupValid={setIsDiffGroupValid}/>
        </div>

        { diffGroups.length !== 0 &&
            <button className="createWorksheet-submit-button" type="button" onClick={handleSubmit}>
                <img 
                    src={generateIcon} alt="Generate"
                    style={{width: "25px", paddingRight: "5px"}}
                />
                Generate and Preview Worksheets
            </button>
        }
        
        </>
    );
}

ConfigureWorksheet.propTypes = {
    file: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    setFile: PropTypes.func, 
    setImageEncodings: PropTypes.func,
    inputType: PropTypes.string, 
    gradeLevels: gradeLevelsPropType,
    diffGroups: diffGroupsPropType,
    setDiffGroups: PropTypes.func, 
    generalInfo: generalInfoPropType,
    setGeneralInfo: PropTypes.func,
    handleUpload: PropTypes.func, 
}


export default ConfigureWorksheet;