import React from "react";
import PropTypes from 'prop-types';
import "./YourActivitiesDashboardHeader.css";
// import filterIcon from "../../../../../assets/shared/filter.svg";
import searchIcon from "../../../../../assets/shared/searchIcon.svg";


function YourActivitiesDashboardHeader({ allActivities, setFilteredActivities}) {
	function searchActivity() {
		// Convert keyword to lowercase for case-insensitive comparison
		const searchInput = document.getElementById("yourActivitiesDashboardHeader-searchInput").value;
		const lowerCaseKeyword = searchInput.toLowerCase();
		console.log(allActivities);

		if (searchInput !== ""){
			// Filter the activities array
			setFilteredActivities(
				Object(allActivities).filter((activity) => activity.activity_name.toLowerCase().includes(lowerCaseKeyword))
			);
		}
		else {
			// show all in activities array
			setFilteredActivities(allActivities);
		}
	}

	return (
		<div className="yourActivitiesDashboardHeader-container">
			<h1 className="yourActivitiesDashboardHeader-heading">Your Worksheets</h1>
			<div className="yourActivitiesDashboardHeader-rightItems">
				<div className="yourActivitiesDashboardHeader-searchBar">
					<input
						id="yourActivitiesDashboardHeader-searchInput"
						className="yourActivitiesDashboardHeader-searchBar-input"
						placeholder="Search Worksheets..."
						autoComplete="off"
						onChange={searchActivity}
					/>
					<img className="yourActivitiesDashboardHeader-searchBar-icon" src={searchIcon} alt="Search" />
				</div>
				{/* <button className="yourActivitiesDashboardHeader-filterButton" type="button">
					<img className="yourActivitiesDashboardHeader-filter" src={filterIcon} alt="filter" />
				</button> */}
			</div>
		</div>
	);
}

YourActivitiesDashboardHeader.propTypes = {
	setFilteredActivities: PropTypes.func,
	allActivities: PropTypes.arrayOf(
		PropTypes.shape({
			activity_name: PropTypes.string,
		})
	),
};


export default YourActivitiesDashboardHeader;
