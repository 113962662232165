import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import '../PreviewWorksheet.css';

function DeleteDropdown({ worksheetIndex, deleteWs }) {
    const [exportDropdownOpen, setExportDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Dropdown minimize when user clicks off of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                exportDropdownOpen
            ) {
                setExportDropdownOpen(false);
            }
        };

        // Attach the event listener to detect clicks outside the dropdown
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [exportDropdownOpen]);

    return (
        <div
            className="previewWorksheet-dropdown"
            ref={dropdownRef}
        >
            <button
                type="button"
                onClick={() => { setExportDropdownOpen(prev => !prev); }}
                className="previewWorksheet-dropbtn"
                style={{backgroundColor: "tomato"}}
            >
                Delete
            </button>

            {exportDropdownOpen && (
                <div
                    id="exportOptionsDropdown"
                    className="previewWorksheet-dropdown-content"
                >
                    <button
                        type="button"
                        className="previewWorksheet-dropdown-option"
                        onClick={() => {
                            setExportDropdownOpen(false);
                            deleteWs(worksheetIndex);
                        }}
                    >
                        Delete this Worksheet Version
                    </button>
                </div>
            )}
        </div>
    );
};

DeleteDropdown.propTypes = {
    worksheetIndex: PropTypes.number,
    deleteWs: PropTypes.func
}

export default DeleteDropdown;