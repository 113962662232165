import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PDFViewer } from '@react-pdf/renderer';
import { useUser, useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';


// import { Document, Page } from 'react-pdf';
import MultipleChoiceWorksheet from '../../../WorksheetTemplates/MultipleChoiceWorksheet';
import FillInTheBlankWorksheet from '../../../WorksheetTemplates/FillInTheBlankWorksheet';
import ReadingComprehensionWorksheet from '../../../WorksheetTemplates/ReadingComprehensionWorksheet'
import WrittenResponseWorksheet from '../../../WorksheetTemplates/WrittenResponseWorksheet';
import LoadingBar from '../../../components/LoadingBar/LoadingBar';

import DeleteDropdown from './Dropdowns/DeleteDropdown';
import ExportDropdown from './Dropdowns/ExportDropdown';

import './PreviewWorksheet.css';
import { singleWorksheetDataPropType } from '../CreateWorksheetConstants';
import PopupConfirm from '../../../components/PopupConfirm/PopupConfirm';

const a4PageStyle = {
    width: '595px',  // A4 width at 72 DPI
    height: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
};

const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    const debouncedFunction = useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);

    return debouncedFunction;
};

function PreviewWorksheet({ worksheetIndex, worksheetData, enableExport, full = false, deleteWs }) {
    const ref = useRef();

    const { data: user } = useUser();

    const [resizeCount, setResizeCount] = useState(0);

    const [exportDropdownOpen, setExportDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const [exportLoading, setExportLoading] = useState(false);

    const [isSlidePopupOpen, setIsSlidePopupOpen] = useState(false);

    const [presentationURL, setPresentationURL] = useState("");

    const config = {
        fontSize: parseInt(worksheetData.accommodations.IEP.accommodations.font_size, 10),
        lineSpacing: parseFloat(worksheetData.accommodations.IEP.accommodations.line_spacing),
        linesBetweenQuestions: 1,
        answerKey: true
    };

    // Cloud Functions
    const functions = useFunctions();
    const exportWorksheetAuth = httpsCallable(functions, 'exportWorksheetAuth');
    const exportWorksheetToGoogleSlides = httpsCallable(functions, 'exportWorksheetToGoogleSlides');

    // Update the PDF instance on resize
    const handleResize = useDebounce(() => {
        setResizeCount(resizeCount + 1);
    }, 500);

    // Resize react-pdf preview on window resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    // Dropdown minimize when user clicks off of it
    useEffect(() => {
        // Function to close dropdown if clicked outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current
                && !dropdownRef.current.contains(event.target)
                && exportDropdownOpen
            ) {
                setExportDropdownOpen(false);
            }
        };

        // Attach the event listener to the document
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef, exportDropdownOpen]);

    /* eslint-disable no-unused-vars */
    const exportToSlidesTest = async () => {
        try {
            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken(true);
            // console.log(token);

            // Step 1: Request the OAuth signing URL
            const response = await fetch('http://127.0.0.1:5001/wizlab-8c906/us-central1/exportWorksheetAuth', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    data: {}
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to get OAuth URL: ${response.statusText}`);
            }

            const result = await response.json();
            console.log(result);
            const { authURL } = result.result;

            // Step 2: Open the OAuth signing URL in a new window and wait for the code

            // Define the dimensions of the popup window
            const width = 600;
            const height = 600;
            const left = (window.innerWidth / 2) - (width / 2);
            const top = (window.innerHeight / 2) - (height / 2);

            const code = await new Promise((resolve, reject) => {
                const authWindow = window.open(authURL, '_blank', `width=${width},height=${height},top=${top},left=${left}`);
                window.addEventListener('message', function authListener(event) {
                    if (event.data.type === 'oauth-code') {
                        window.removeEventListener('message', authListener);
                        authWindow.close();
                        resolve(event.data.code);
                    } else if (event.data.error) {
                        window.removeEventListener('message', authListener);
                        authWindow.close();
                        reject(new Error('OAuth error: ', event.data.error));
                    }
                });

                // Handle popup closure by the user
                const popupCheckInterval = setInterval(() => {
                    if (authWindow.closed) {
                        clearInterval(popupCheckInterval);
                        reject(new Error('Popup closed by user'));
                    }
                }, 1000);
            });

            console.log("Code:", code);

            // Step 3: Use the OAuth code to make the second request
            setExportLoading(true);
            const presentationResponse = await fetch('http://127.0.0.1:5001/wizlab-8c906/us-central1/exportWorksheetToGoogleSlides', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    data: {
                        OAuthCode: code,
                        worksheetTitle: worksheetData.worksheet_title,
                        worksheetType: worksheetData.worksheet_type,
                        worksheetData,
                        worksheetIndex,
                        config,
                    }
                })
            });

            if (!presentationResponse.ok) {
                throw new Error(`Failed to submit OAuth code: ${presentationResponse.statusText}`);
            }

            const presentationData = await presentationResponse.json();
            console.log(presentationData);
            const { presentationId } = presentationData.result;
            console.log("Presentation:", presentationId);

            setExportLoading(false);
            // Construct the Google Slides URL
            const presentationURLString = `https://docs.google.com/presentation/d/${presentationId}/edit`;

            // Open the presentation in a new tab
            window.open(presentationURLString, '_blank');
        } catch (error) {
            console.error('Error uploading to Firebase:', error);
            throw error; // Re-throw the error if you want to handle it further up the call stack
        }
    };

    /* eslint-disable no-unused-vars */
    const exportToSlidesCallCloudFunctions = async () => {
        try {
            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken(true);
            // console.log(token);

            // Step 1: Request the OAuth signing URL
            const response = await exportWorksheetAuth(
                { data: {} },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
            );


            const result = response.data;
            console.log(result);
            const { authURL } = result;

            // Step 2: Open the OAuth signing URL in a new window and wait for the code

            // Define the dimensions of the popup window
            const width = 600;
            const height = 600;
            const left = (window.innerWidth / 2) - (width / 2);
            const top = (window.innerHeight / 2) - (height / 2);

            const code = await new Promise((resolve, reject) => {
                const authWindow = window.open(authURL, '_blank', `width=${width},height=${height},top=${top},left=${left}`);
                window.addEventListener('message', function authListener(event) {
                    if (event.data.type === 'oauth-code') {
                        window.removeEventListener('message', authListener);
                        authWindow.close();
                        resolve(event.data.code);
                    } else if (event.data.error) {
                        window.removeEventListener('message', authListener);
                        authWindow.close();
                        reject(new Error('OAuth error: ', event.data.error));
                    }
                });

                // Handle popup closure by the user
                const popupCheckInterval = setInterval(() => {
                    if (authWindow.closed) {
                        clearInterval(popupCheckInterval);
                        reject(new Error('Popup closed by user'));
                    }
                }, 1000);
            });

            console.log("Code:", code);

            // Step 3: Use the OAuth code to make the second request
            setExportLoading(true);
            const presentationResponse = await exportWorksheetToGoogleSlides(
                {
                    OAuthCode: code,
                    worksheetTitle: worksheetData.worksheet_title,
                    worksheetType: worksheetData.worksheet_type,
                    worksheetData,
                    worksheetIndex,
                    config,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                },
            );

            const presentationData = presentationResponse.data;
            console.log(presentationData);
            const { presentationId } = presentationData;
            console.log("Presentation:", presentationId);

            setExportLoading(false);
            // Construct the Google Slides URL
            const presentationURLString = `https://docs.google.com/presentation/d/${presentationId}/edit`;

            // Open the presentation in a new tab
            window.open(presentationURLString, '_blank');
        } catch (error) {
            console.error('Error exporting to slides:', error);
            setExportLoading(false);
            throw error; // Re-throw the error if you want to handle it further up the call stack
        }
    };

    /* eslint-disable no-unused-vars */
    const exportToSlidesWithoutOAuthTest = async () => {
        try {
            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken(true);

            setExportLoading(true);

            const presentationResponse = await fetch('http://127.0.0.1:5001/wizlab-8c906/us-central1/exportWorksheetToGoogleSlides', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    data: {
                        worksheetTitle: worksheetData.worksheet_title,
                        worksheetType: worksheetData.worksheet_type,
                        worksheetData,
                        worksheetIndex,
                        config,
                    }
                })
            });

            if (!presentationResponse.ok) {
                throw new Error(`Failed to submit OAuth code: ${presentationResponse.statusText}`);
            }

            const presentationData = await presentationResponse.json();
            console.log(presentationData);
            const { presentationId } = presentationData.result;
            console.log("Presentation:", presentationId);

            setExportLoading(false);
            // Construct the Google Slides URL
            const presentationURLString = `https://docs.google.com/presentation/d/${presentationId}/edit`;

            // Open the presentation in a new tab
            window.open(presentationURLString, '_blank');

            setIsSlidePopupOpen(true);

        } catch (error) {
            setExportLoading(false);
            console.error('Error exporting to google slides:', error);
            throw error; // Re-throw the error if you want to handle it further up the call stack
        }

    }

    /* eslint-disable no-unused-vars */
    const exportToSlidesWithoutOAuthCallVersion = async () => {
        try {
            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken(true);

            setExportLoading(true);
            const presentationResponse = await exportWorksheetToGoogleSlides(
                {
                    worksheetTitle: worksheetData.worksheet_title,
                    worksheetType: worksheetData.worksheet_type,
                    worksheetData,
                    worksheetIndex,
                    config,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                },
            );

            const presentationData = presentationResponse.data;
            console.log(presentationData);
            const { presentationId } = presentationData;
            console.log("Presentation:", presentationId);

            setExportLoading(false);
            // Construct the Google Slides URL
            const presentationURLString = `https://docs.google.com/presentation/d/${presentationId}/edit`;

            setPresentationURL(presentationURLString);

            setIsSlidePopupOpen(true);

            // Open the presentation in a new tab
            window.open(presentationURLString, '_blank');

        } catch (error) {
            console.error('Error exporting to slides:', error);
            setExportLoading(false);
            throw error; // Re-throw the error if you want to handle it further up the call stack
        }
    }


    let worksheetDocument = null;

    switch (worksheetData.worksheet_type) {
        case "multiple choice":
            worksheetDocument = <MultipleChoiceWorksheet
                isPdf={enableExport}
                worksheetData={worksheetData}
                worksheetIndex={worksheetIndex}
                config={config}
            />
            break;

        case "reading comprehension":
            worksheetDocument = <ReadingComprehensionWorksheet
                isPdf={enableExport}
                worksheetData={worksheetData}
                worksheetIndex={worksheetIndex}
                config={config}
            />
            break;
        case "written response":
            worksheetDocument = <WrittenResponseWorksheet
                isPdf={enableExport}
                worksheetData={worksheetData}
                worksheetIndex={worksheetIndex}
                config={config}
            />
            break;
        default:
            worksheetDocument = <FillInTheBlankWorksheet
                isPdf={enableExport}
                worksheetData={worksheetData}
                worksheetIndex={worksheetIndex}
                config={config}
            />
            break;
    }

    if (!ref.current) {
        handleResize();
    }

    return (
        <div className="previewWorksheet-outer-container">{(!full) &&
            <div
                ref={ref}
                className="previewWorksheet-container"
                key={`preview-${worksheetIndex}`}
            >
                <div
                    className="previewWorksheet-header">
                    <h3 className="previewWorksheet-title">Preview</h3>
                    {enableExport &&
                        <div className="previewWorksheet-dropdown">
                            <ExportDropdown
                                worksheetDocument={worksheetDocument}
                                worksheetData={worksheetData}
                                worksheetIndex={worksheetIndex}
                                exportToSlides={exportToSlidesWithoutOAuthCallVersion}
                            />
                            <DeleteDropdown
                                worksheetIndex={worksheetIndex}
                                deleteWs={deleteWs}
                            />
                        </div>
                    }
                </div>
                {(!exportLoading && enableExport) &&
                    <PDFViewer
                        showToolbar={false}
                        style={{ width: ref.current ? ref.current.offsetWidth - 50 : "40vw", height: ref.current ? (ref.current.offsetWidth - 50) * 1.5 : "80vh" }}
                        key={`preview-PDFPreview-${worksheetIndex}`}
                    >
                        {worksheetDocument}
                    </PDFViewer>
                }
                {(!exportLoading && !enableExport) &&
                    (<div style={{overflowY: "scroll", border: '2px solid #ccc', width: "clamp(30%, 595px, 50px)",
                        borderRadius: '4px', ...a4PageStyle}}>{worksheetDocument}</div>)
                }
                {exportLoading &&
                    <LoadingBar duration="10" />
                }
            </div>
        }
        {
            (full) && (<div style={a4PageStyle}>{worksheetDocument}</div>)
        }
        {   isSlidePopupOpen &&
            <PopupConfirm
                displayText="Click to open Google Slide!" 
                confirmText="Open" exitText="Close" handleConfirm={() => window.open(presentationURL, '_blank')} 
                handleExit={() => setIsSlidePopupOpen(false)}
            /> 
        }
        </div>
    );
};


PreviewWorksheet.propTypes = {
    worksheetIndex: PropTypes.number,
    worksheetData: singleWorksheetDataPropType,
    enableExport: PropTypes.bool,
    full: PropTypes.bool,
    deleteWs: PropTypes.func
}

export default PreviewWorksheet;
