import React from "react";
import "./ErrorScreen.css";
import Navbar from "../../components/NavBar/NavBar";
import errorScreenImg from '../../assets/ErrorScreen/errorScreenImg.png'


function ErrorScreen() {
	return (
		<div className="base-container">
			<Navbar/>
			<div className="base-content-container">
				<div className="errorScreen-container">
                    <div className="errorScreen-content">
                    <img className="errorScreen-img" src={errorScreenImg} alt="Error Screen"/>
                    <h1 className="errorScreen-text">There seems to have been an error. Refresh and try again!</h1>
                    </div>
				</div>
			</div>
		</div>
	);
}

export default ErrorScreen;
