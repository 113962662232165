import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useUser, useFirestore } from 'reactfire';
import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';

import "../CreateWorksheet/CreateWorksheet.css";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import Navbar from "../../components/NavBar/NavBar";
import PreviewEditWorksheet from "../CreateWorksheet/PreviewEditView/PreviewEditWorksheet";
import FinishWorksheet from "../CreateWorksheet/FinishView/FinishWorksheet";
import ErrorScreen from "../ErrorScreen/ErrorScreen";

// import { sampleWorksheetMultipleChoice as sampleWorksheetResultData } from "./CreateWorksheetSampleData";


function EditWorksheet() {

    const navigate = useNavigate();

    const [wid, setWid] = useState('');

    // STATUSES: initial, loading, returned, finish, error
    const [status, setStatus] = useState("loading");

    const [worksheetData, setWorksheetData] = useState([]); // sampleWorksheetResultData

    // Auth
    const { data: user } = useUser();

    // Access Firestore and Auth services
    const firestore = useFirestore();

    // Extract 'wid' from URL query parameters
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Set wid from the 'id' query parameter in the URL
        const worksheetIdFromUrl = searchParams.get('id');
        if (worksheetIdFromUrl) {
            setWid(worksheetIdFromUrl);
        } else {
            setStatus("error");
        }
    }, [searchParams]);

    useEffect(() => {
        if (!user || !wid) {
            return;
        }

        const fetchWorksheetSets = async () => {
            try {
                // Reference to the collection containing the worksheets
                const worksheetsDocRef = doc(firestore, 'users', user.uid, 'worksheet_sets', wid);

                // Fetch the document
                const worksheetSet = await getDoc(worksheetsDocRef);

                // Check if the document exists
                if (worksheetSet.exists()) {
                    // Retrieve the document data
                    const retrievedWorksheetData = worksheetSet.data();

                    // Access the 'worksheetData' field from the document data
                    const worksheetsArray = retrievedWorksheetData.worksheetData;

                    // Set the fetched data in the state
                    setWorksheetData(worksheetsArray);

                    // Set status to returned
                    setStatus("finish");
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching worksheet data:', error);
            }
        };

        fetchWorksheetSets();
    }, [firestore, user, wid]);

    async function addWorksheetsForUser(worksheetCollectionTitle, worksheetsArray) {
        try {
            if (!user) {
                console.error("No user is signed in");
                return;
            }

            // Reference the 'worksheets' subcollection
            const worksheetsDocRef = doc(firestore, 'users', user.uid, 'worksheet_sets', wid);

            // Set or update fields directly in the 'wid' document
            await setDoc(worksheetsDocRef, {
                // your data fields go here
                title: worksheetCollectionTitle,
                size: worksheetsArray.length,
                worksheetData: worksheetsArray,
                createdAt: new Date()
            });

            console.log('All worksheets added successfully.');
        } catch (error) {
            console.error('Error adding worksheets: ', error.message);
        }
    }

    const finishAndSave = async (worksheetDataFinal) => {

        console.log("worksheet data final:", worksheetDataFinal);
        addWorksheetsForUser(worksheetDataFinal[0].worksheet_title, worksheetDataFinal);
        setStatus("finish");
    }

    const deleteWorksheetSet = async() => {
        try {
            if (!user) {
                console.error("No user is signed in");
                return;
            }

            // Reference the specific worksheet set document in the 'worksheet_sets' subcollection
            const worksheetDocRef = doc(firestore, 'users', user.uid, 'worksheet_sets', wid);

            // Delete the document
            await deleteDoc(worksheetDocRef);

            navigate('/home');

            console.log('Worksheet set deleted successfully.');
        } catch (error) {
            console.error('Error deleting worksheet set: ', error.message);
        }
    }

    const deleteWorksheet = async (index) => {
        try {
            // Get the current worksheet data
            setWorksheetData((prevWorksheetData) => {
                // Check if index is within bounds
                if (index >= 0 && index < prevWorksheetData.length && prevWorksheetData.length > 1) {
                    // Create a new array without the worksheet at the given index
                    const updatedWorksheetData = [...prevWorksheetData];
                    updatedWorksheetData.splice(index, 1);
                    finishAndSave(updatedWorksheetData);
                    // Return the updated array to update the state
                    return updatedWorksheetData;
                }
                deleteWorksheetSet();
                return prevWorksheetData;
            });
            console.log(`Worksheet at index ${index} deleted successfully.`);
        } catch (error) {
            console.error('Error deleting worksheet: ', error.message);
        }
    };

    return (
        <div className="base-container">
            <Navbar minimizedByDefault />
            <div className="base-content-container">
                <div className="createWorksheet-container">
                    {status === 'error' &&
                        <ErrorScreen/>
                    }
                    {status === 'loading' &&
                        <LoadingBar duration="1" />
                    }
                    {status === "returned" &&
                        <PreviewEditWorksheet
                            worksheetDataInput={worksheetData}
                            finishAndSave={finishAndSave}
                        />
                    }
                    {status === "finish" && worksheetData &&
                        <FinishWorksheet
                            setDiffGroups={() => {}}
                            worksheetData={worksheetData}
                            setStatus={setStatus}
                            deleteWss={deleteWorksheetSet}
                            deleteWs={deleteWorksheet}
                        />
                    }
                </div>
            </div>
        </div>
    );
}


export default EditWorksheet;
