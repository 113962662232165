import React from 'react';
import PropTypes from 'prop-types';
import { PageWrap, ImageWrap, ViewWrap, DocumentWrap, TextWrap, EmbeddedTextWrap } from './WorksheetWrappers';

import WorksheetHeaderBanner from './assets/WorksheetHeaderBanner2.png';
import WorksheetFooterBanner from './assets/WorksheetFooterBanner2.png';

import { singleWorksheetDataPropType } from '../pages/CreateWorksheet/CreateWorksheetConstants';
import AnswerKey from './AnswerKey';
import { splitText } from './ChineseAccessability';

import './WorksheetTemplate.css';

// Create Document Component
function WorksheetTemplate({ worksheetData, worksheetIndex, children, config, isPdf}) {

    const worksheetTitle = worksheetData.worksheet_title ? splitText(worksheetData.worksheet_title) : "Fill In The Blank!";

    return (
        <DocumentWrap isPdf={isPdf}>
            <PageWrap
                isPdf={isPdf}
                size="A4"
                // style={worksheetData.accommodations.IEP.accomodations.includes('Dyslexia') ? styles.dyslexicMode : styles.page}
                styleProps={config}
                key={`${worksheetIndex}-page`}
            >
                <ViewWrap isPdf={isPdf} styleProps={config} styleName="header" elementKey="header">
                    <ImageWrap isPdf={isPdf} styleProps={config} styleName="headerBanner" src={WorksheetHeaderBanner} />
                </ViewWrap>
                <ViewWrap isPdf={isPdf} styleProps={config} styleName="content" elementKey="content">
                    <>
                        {(worksheetIndex === -1) && <TextWrap
                                    isPdf={isPdf}
                                    styleProps={config}
                                    styleNames={['h1']}
                                    > </TextWrap>}
                        <EmbeddedTextWrap
                            isPdf={isPdf}
                            styleProps={config}
                            styleName='h1'
                            elementKey="worksheetTitle"
                        >
                            {worksheetTitle.map((part, index) => (
                                <TextWrap
                                    key={`titleSegment-${index + 1}`}
                                    isPdf={isPdf}
                                    styleProps={config}
                                    styleNames={['h1', 'inlineText', part.isChinese ? 'chineseText' : '']}
                                    elementKey={`titleSegment-${index + 1}`}
                                >
                                    {part.text}
                                </TextWrap>
                            ))}

                        </EmbeddedTextWrap>

                        <TextWrap
                            isPdf={isPdf}
                            styleProps={config}
                            styleNames={['instructions']}
                            elementKey="instructionsText"
                        >
                            Fill out this worksheet by circling the correct answer.
                        </TextWrap>

                    </>

                    {children}

                    {(worksheetIndex === -1) && <TextWrap
                                    isPdf={isPdf}
                                    styleProps={config}
                                    styleNames={['h1']}
                                    > <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></TextWrap>}

                </ViewWrap>
                <ViewWrap isPdf={isPdf} styleProps={config} styleName="footer" elementKey="footer">
                    <ImageWrap isPdf={isPdf} styleProps={config} styleName="footerBanner" src={WorksheetFooterBanner} />
                </ViewWrap>
            </PageWrap>
            {config.answerKey && <AnswerKey worksheetData={worksheetData} worksheetIndex={worksheetIndex} isPdf={isPdf}/>}
        </DocumentWrap>
    );
}

WorksheetTemplate.propTypes = {
    worksheetData: singleWorksheetDataPropType,
    worksheetIndex: PropTypes.number,
    children: PropTypes.node.isRequired,
    isPdf: PropTypes.bool.isRequired,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
        linesBetweenQuestions: PropTypes.number,
        answerKey: PropTypes.bool
    })
}

export default WorksheetTemplate;
